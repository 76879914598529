import React from 'react';
import { Column, Container, Row } from '../../components/Grid';
import Hero from '../../components/Hero';
import Layout from '../../components/Layout';
import Section from '../../components/Section';

import Image from '../../components/Image';
import Resource from '../../components/Resource';
import '../../styles/pages/_ccready.scss';
import '../../styles/pages/_classroom-instruction.scss';
import '../../styles/styles.scss';

const CareerFinder = () => {
  return (
    <Layout title="Career Connect" className="career-connect">
      <Hero
        mediaSource={'GEN-CC-hero-banner.jpg'}
        className={'hero--large hero--career-connect mb-0 pb-0'}
        color={'blue'}
      ></Hero>
      <Container>
        <Section>
          <Row>
            <Column size={9}>
              <h2 className="career-connect__title">
                Today’s students are tomorrow’s workforce. Let’s inspire them.
              </h2>
              <p>
                Discovery Education has connected thousands of professionals to
                classrooms across the country. Now with Career Connect you can
                seamlessly connect your classroom with vetted professionals when
                you want, as often as you want, for free, to spark classroom
                engagement and inspire students to consider new possibilities.
              </p>
            </Column>
          </Row>
        </Section>
        <Section>
          <Row>
            <Column size={6}>
              <Image filename="career-connect-thumb-1.png" />
            </Column>
            <Column size={6}>
              <h3 className="career-connect__card-1-title">
                Inspire Your Students With Real-Time Discussions
              </h3>
              <p>
                Futurelab+ volunteers are available and eager to connect
                virtually with your classroom through Career Connect.  Have
                students explore duties and goals of various careers in
                biotechnology to create thoughtful questions to ask industry
                professionals. Invite an industry professional to virtually
                share equipment, data, or other tools they use to prompt student
                discussion about their use and impact in the field of
                biotechnology. 
                <br /> <br />
                The biotech industry has a wealth of career pathways to
                consider. Connect your students with employee volunteers
                currently working in the field today to share career insights,
                the latest innovations, inspiration and more!
              </p>
              <a
                href="http://careerconnect.discoveryeducation.com/"
                target="_blank"
                className="career-connect__card-1-link"
              >
                LEARN MORE ABOUT CAREER CONNECT
              </a>
            </Column>
          </Row>
          <Row className="pt-2">
            <Column size={6}>
              <Image filename="CC-Spotlight-Richard.jpg" />
            </Column>
            <Column size={6}>
              <p className="career-connect__card-2-tag">
                Career Connect Spotlight with Futurelab+ featuring:
              </p>
              <h3 className="career-connect__card-1-title">
                Richard Bonneau, Vice President of Machine Learning for Drug
                Discovery, Genentech Computational Sciences
              </h3>
              <p className="career-connect__card-2-date">Feb 27th 1pm ET</p>
              <p>Grades 9-12</p>
              <p>
                Are your students curious about how AI is driving innovation and
                shaping the future? Do they wonder how this technology will
                impact their future careers and the workforce of tomorrow? Join
                us and Richard Bonneau, Vice President of Machine Learning for
                Drug Discovery, Genentech Computational Sciences to dive into
                the world of AI and biotechnology and learn how machine learning
                accelerates the discovery of new medicines that could treat
                diseases like cancer.
              </p>
              <p className="career-connect__card-2-note">
                Hear more about his area of expertise in,{' '}
                <a
                  href="https://www.gene.com/stories/ai-and-the-future-of-medicine?topic=two-scientists-walk-into-a-bar"
                  target="_blank"
                >
                  AI and the Future of Medicine
                </a>
                , from the Genentech podcast series Two Scientists Walk Into A
                Bar
              </p>
              <a
                href="https://www.discoveryeducation.com/info/career-connect-spotlight-futurelabplus/"
                target="_blank"
                className="career-connect__card-1-link"
              >
                REGISTER NOW
              </a>
            </Column>
          </Row>
        </Section>
        <Section>
          <Row>
            <Column size={9}>
              <h2 className="career-connect__title">
                Career Connect Resources
              </h2>
              <p>
                Bringing real-world stories and career shares into the classroom
                through guest speakers is a powerful way to bring concepts to
                life. Use these resources to help you prepare for an upcoming
                connect with a biotech professional or as a stand-alone
                exploration around the breadth of possibilities in the biotech
                industry.
              </p>
            </Column>
          </Row>
        </Section>
        <Section>
          <Row>
            <Column size={4}>
              <Resource
                tophat="a"
                tophatClass="career-connect__resource-tophat"
                img="CC-resources-AskTheExpert.jpg"
                subject="CAREER CONNECT"
                title="Ask the Expert"
                titleClass="capstone-project__resource-title"
                description="This classroom activity helps your students prepare to meet a guest speaker from today’s workforce in bioengineering, molecular biologists, medicine manufacturing, and more."
                asCard
                actions={[
                  {
                    label: 'EDUCATOR GUIDE',
                    type: 'external',
                    url:
                      'https://app.discoveryeducation.com/learn/player/c42799e3-f951-4c7c-a448-8d8f3e096b7f',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat="a"
                img="CC-resources-RoundRobin.jpg"
                tophatClass="career-connect__resource-tophat"
                subject="CAREER CONNECT"
                title="Round Robin Tournament"
                titleClass="capstone-project__resource-title"
                description="In this biotech round-robin tournament activity, the teacher will lead students in learning about various facets of biotech industries."
                asCard
                actions={[
                  {
                    label: 'Activity',
                    type: 'external',
                    url:
                      'https://app.discoveryeducation.com/learn/player/c9f83110-a9d6-4e01-a017-2863018f9fff',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat="a"
                img="CC-resources-IndustryProfile.jpg"
                tophatClass="career-connect__resource-tophat"
                subject="CAREER CONNECT"
                title="Project Starters"
                titleClass="capstone-project__resource-title"
                description="Find thirteen in-class ideas for students. Ideas include microbial bioremediation, antibiotic sensitivity testing, biomedical engineering design challenge, and many more!"
                asCard
                actions={[
                  {
                    label: 'Industry profile',
                    type: 'external',
                    url:
                      'https://app.discoveryeducation.com/learn/player/31d61785-5e6e-43d7-bec3-6c89f8fd3cea',
                  },
                ]}
              />
            </Column>
          </Row>
        </Section>
      </Container>
    </Layout>
  );
};

export default CareerFinder;
